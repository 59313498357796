import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default React.forwardRef((props, ref) => {
	const [state, setstate] = useState(0);
	const onChange = (content, delta, source, editor) => {
		setstate(editor.getLength() - 1);

		editor.getLength() - 1 === 0
			? props.onChange(null)
			: props.onChange(content);
  };
  
  useEffect(() => {
    return () => {
      setstate(0);
      props.onChange(null);
    }
  }, []);

	return (
		<div className="TextArea">
			<ReactQuill
        onChange={onChange}
        placeholder='Please enter candidate description.'
				// onChange={(content, delta, source, editor) => {
				// form.setFieldsValue({
				// 	choice_description_length:
				// 		editor.getLength() - 1,
				// });
				// setDescriptionLength(
				// 	editor.getLength() - 1
				// );
				// }}
			/>
      <div className="TextAreaLength">
          {state}
          /
          {props.maxLength}
        </div>
		</div>
	);
});