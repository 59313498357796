import React, { useState } from 'react';
import { Form, Input, Button, Rate, Divider } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Upload } from 'components';
import { TextArea } from 'components/formElements';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default ({ fields, add, remove, t, initial = 3, form }) => {
	return (
		<div>
			<div className="SurveyChoices">
				{fields.map((field, index) => (
					<div key={field.key} className="SurveyChoice">
						<div className="ChoiceImage">
							<Form.Item
								name={[field.name, 'id']}
								fieldKey={[field.fieldKey, 'id']}
								initialValue={null}
								className="d-none"
							>
								<Input />
							</Form.Item>
							<Form.Item
								label=" "
								name={[field.name, 'choice_image']}
								fieldKey={[field.fieldKey, 'choice_image']}
								rules={[
									{
										required: true,
										message: t(
											'msg.candidate_image_required'
										),
									},
								]}
							>
								<Upload />
							</Form.Item>
							<Form.Item
								name={[field.name, 'marking']}
								fieldKey={[field.fieldKey, 'marking']}
								rules={[
									{
										validator: async (_, marking) => {
											if (!marking || marking === 0) {
												return Promise.reject(
													new Error(
														t('msg.rating_required')
													)
												);
											}
										},
									},
								]}
							>
								<Rate />
							</Form.Item>
						</div>
						<div className="ChoiceInfo">
							<Form.Item
								label={t('lbl.name')}
								name={[field.name, 'choice_title']}
								fieldKey={[field.fieldKey, 'choice_title']}
								rules={[
									{
										required: true,
										message: t('msg.required_title'),
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label={t('lbl.description')}
								name={[field.name, 'choice_description']}
								fieldKey={[
									field.fieldKey,
									'choice_description',
								]}
								rules={[
									{
										required: true,
										message: t('msg.required_description'),
									},
									{
										validator: async (
											_,
											choice_description
										) => {
											console.log('valid', choice_description?.replace(/(<([^>]+)>)/ig,"").length);
											if (choice_description?.replace(/(<([^>]+)>)/ig,"").length > 1000) {
												return Promise.reject(
													new Error(
														t(
															'msg.error_exceed_max_character'
														)
													)
												);
											}
										},
									},
								]}
							>
								{/* <ReactQuill
									onChange={(
										content,
										delta,
										source,
										editor
									) => {
										// editor.getLength() - 1 === 0
										// 	? form.setFieldsValue({
										// 			choice_description: null,
										// 	  })
										// 	: form.setFieldsValue({
										// 			choice_description: content,
										// 	  });
										// form.setFieldsValue({
										// 	choice_description_length:
										// 		editor.getLength() - 1,
										// });
										// setDescriptionLength(
										// 	editor.getLength() - 1
										// );

									}}
								/> */}
								<TextArea maxLength={1000} form={form} key={index} />
							</Form.Item>
						</div>
						{index > initial && (
							<Button
								icon={<DeleteOutlined />}
								onClick={() => remove(field.name)}
								//style={{margin: 'auto'}}
							/>
						)}
					</div>
				))}
			</div>
			<Divider />
			<div className="text-center">
				<Button icon={<PlusOutlined />} onClick={() => add()}>
					{t('lbl.new_choice')}
				</Button>
			</div>
		</div>
	);
};
