import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { auth, global } from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({ auth, global });
let store;

if (process.env.NODE_ENV === 'development') {
  store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk, logger))
  );
}
else {
  store = createStore(
    reducer,
    applyMiddleware(thunk)
  );
}

export default store;